import { Injectable } from '@angular/core';
import { BinaryStorageService } from '@cache/binary-storage.service';
import { ApiError } from '@models/errors/api-error';
import { TreePosition, SortOptions } from '@shared/d3/d3-graph.constants';
import {
  generateFixedNoResultNodeData,
  generateNodeId,
  getNextOrPrev,
  defaultPaginationOptions,
} from '@shared/d3/d3-graph.utils';
import { UpsertContentFiles } from '@store/actions/content-file.action';
import { selectCurrentConfig } from '@store/selectors/configs.selectors';
import { RxjsUtils } from '@utils/rxjs/rxjs-utils';
import { DataElementType } from '@constants/data-element-types';
import { GraphConstants as GC } from '@constants/graph-constants';
import { Config } from '@models/fabrication/config';
import { ContentFile, StorageFile } from '@models/fabrication/files';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { FabricationReference, FabricationReferenceType } from '@models/forge-content/references';
import { Action, Store } from '@ngrx/store';
import {
  Cluster,
  GraphData,
  GraphNode,
  HierarchyGraphNode,
  PaginationMetaData,
  PaginationOptions,
} from '@shared/d3/d3-graph.types';
import { FDMState } from '@store/reducers/index';
import { hierarchy } from 'd3';
import {
  drop,
  escapeRegExp,
  flatten,
  isEmpty,
  orderBy,
  pick,
  sortBy,
  take as _take,
  uniq,
} from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { BulkDataLoadingService } from '.';
import { selectAllParts } from '@store/selectors/part.selectors';
import { DynamicDataService } from '../dynamic-data.service';
import { ForgeContentService } from '../forge-content.service';
import { LoadData, LoadDataSuccess } from '@store/actions/base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';
import { selectThumbnailFileById } from '@store/selectors/thumbnail-file.selectors';
import { ContentFileUtils } from '@utils/content-file-utils';
import { UpsertThumbnailFiles } from '@store/actions/thumbnail-file.action';
import { Part } from '@models/fabrication/part';

export enum ReferenceType {
  DOWNSTREAM,
  UPSTREAM,
}

export interface ReferenceMap {
  dataElement: ForgeContentDataElement;
  dataElementType: DataElementType;
  referenceData: FabricationReference;
}

export interface ReferenceOutput {
  referenceMap: ReferenceMap;
  contentFiles?: ContentFile[];
}

export interface ReferenceDataTypeMap {
  dataType: DataElementType;
  reference: FabricationReference;
}

export interface DynamicGraphHierarchy {
  [TreePosition.UPSTREAM]?: HierarchyGraphNode;
  [TreePosition.DOWNSTREAM]?: HierarchyGraphNode;
}

/**
 * required data to search and find node references
 */
interface NodeReferenceSearchClue {
  dataElementId: string;
  dataType: DataElementType;
  refType: ReferenceType;
  internalRelationshipId?: string;
  /*
   * prop to save the external id of currently updated part and exclude it
   * from the search API call, to avoid refetch of oudated info,
   * because is already updated in the store
   */
  updatedPartExternalId?: string;
  isInvalidData: boolean;
}

interface NodeOptions {
  isExpandable?: boolean;
  isReplaceable?: boolean;
  isRemovable?: boolean;
  isEditable?: boolean;
  isFocusable?: boolean;
}

class GraphNodeBuilderDirector {
  static makeNoDataGraphNode(): GraphNode {
    return {
      id: '-2',
      dataType: GC.PLACEHOLDER_GRAPH_NODE_NODATATYPE,
      isExpandable: false,
      hasNoReference: true,
    };
  }

  static makeEmptySlotNode(
    dataType: DataElementType,
    nodeOptions: NodeOptions,
    referenceMetaData?: FabricationReference
  ): GraphNode {
    return {
      id: '-1',
      dataType,
      isUnset: true,
      hasNoReference: true,
      ...nodeOptions,
      isExpandable: false,
      info: {
        name: ' ',
        category: ' ',
      },
      referenceMetaData,
    };
  }

  static makeDataElementGraphNode(
    dataElement: ForgeContentDataElement,
    dataType: DataElementType,
    infoNodeFields: string[],
    nodeOptions: NodeOptions,
    referenceMetaData: FabricationReference,
    isInvalidData: boolean,
    requiresUpgrade: boolean
  ): GraphNode {
    return {
      id: dataElement.externalId,
      dbid: dataElement.id,
      dataType,
      ...nodeOptions,
      info: pick(dataElement, infoNodeFields),
      referenceMetaData,
      isInvalidData,
      requiresUpgrade,
      isUpgrading: dataElement.isUpgrading,
    };
  }
}

const excludedDataTypes = [
  DataElementType.Image,
  DataElementType.DBFile,
  DataElementType.Permissions,
];

@Injectable()
export class DynamicGraphDataService {
  hierarchySubject: BehaviorSubject<DynamicGraphHierarchy> = new BehaviorSubject({});
  hierarchy$: Observable<DynamicGraphHierarchy> = this.hierarchySubject.asObservable();
  useTransition = false;

  dataElementCursor: ForgeContentDataElement;
  configCursor: Config;

  constructor(
    private bulkLoadDataService: BulkDataLoadingService,
    private dynamicDataService: DynamicDataService,
    private fileStorageService: BinaryStorageService,
    private forgeContentService: ForgeContentService,
    private store$: Store<FDMState>
  ) {
    this.useTransition = false; // temporary off transitions
  }

  /**
   * It resolves the data for initial graph central node
   *
   * @param {strin} dataElementId
   * @returns {Observable<GraphData>}
   * @memberof DynamicGraphDataService
   */
  getInitialGraphData(
    dataElementId: string,
    dataType: DataElementType
  ): Observable<[GraphData, GraphData]> {
    const getLeftGraphNode = this.getDataElementAndCreateGraphNode(
      dataElementId,
      dataType,
      null,
      ReferenceType.UPSTREAM,
      dataType === DataElementType.InvalidData
    );

    const getRightGraphNode = this.getDataElementAndCreateGraphNode(
      dataElementId,
      dataType,
      null,
      ReferenceType.DOWNSTREAM,
      dataType === DataElementType.InvalidData
    );

    return RxjsUtils.concurrentForkJoin([getLeftGraphNode, getRightGraphNode]).pipe(
      take(1),
      switchMap((initialGraphNodes: [GraphNode, GraphNode]) => {
        return this.assignGraphNodeImages(initialGraphNodes).pipe(
          map(() => {
            return initialGraphNodes;
          })
        );
      }),
      take(1)
    );
  }

  getStreamReferences(nodeReferencesSearchClue: NodeReferenceSearchClue): Observable<GraphNode[]> {
    return this.getDataElementWithConfigAndSetCursors(nodeReferencesSearchClue).pipe(
      switchMap(() => {
        // load the data elements dependent types to ensure all the data required is loaded
        const dependentTypes = this.getDependentDataTypes(
          this.dataElementCursor,
          nodeReferencesSearchClue
        );
        if (dependentTypes?.length) {
          return this.bulkLoadDataService.bulkLoadDataAndDependents(dependentTypes, false);
        } else {
          return of(true);
        }
      }),
      switchMap(() => {
        return this.getStoreReferencesAndCreateGraphNodes(nodeReferencesSearchClue);
      })
    );
  }

  getDataElementWithConfigAndSetCursors(
    nodeReferencesSearchClue: NodeReferenceSearchClue
  ): Observable<[ForgeContentDataElement, Config]> {
    const { dataElementId, dataType, isInvalidData } = nodeReferencesSearchClue;
    const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
      dataType,
      dataElementId
    );

    return combineLatest([
      dynamicDataSetup.options.selectors.selectById(dataElementId, isInvalidData),
      this.store$.select(selectCurrentConfig),
    ]).pipe(
      take(1),
      tap(([element, currentConfig]: [ForgeContentDataElement, Config]) => {
        // load the data elements dependent types to ensure all the data required is loaded
        this.dataElementCursor = element;
        this.configCursor = currentConfig;
      })
    );
  }

  /**
   * Loads dependent data types for the requested graph data element.
   * This includes all of the data type's dependents defined in the dynamic setup options
   * @param {ForgeContentDataElement} dataElement
   * @param {DataElementType} dataType
   * @returns {DataElementType[]}
   * @memberof DynamicGraphDataService
   */
  getDependentDataTypes(
    dataElement: ForgeContentDataElement,
    nodeReferencesSearchClue: NodeReferenceSearchClue
  ): DataElementType[] {
    const { dataType, refType } = nodeReferencesSearchClue;

    const dependentTypes: DataElementType[] = [];

    if (dataElement?.fabricationReferences?.length && refType === ReferenceType.DOWNSTREAM) {
      dependentTypes.push(...dataElement.fabricationReferences.map((x) => x.dataType));
    } else {
      const setup = this.dynamicDataService.getDynamicDataSetupForType(dataType);
      dependentTypes.push(
        ...setup.getDynamicGraphOptions().upstreamReferenceDataTypes(dataElement)
      );
    }

    dependentTypes.push(DataElementType.InvalidData);

    return uniq(dependentTypes);
  }

  getStoreReferencesAndCreateGraphNodes(
    nodeReferencesSearchClue: NodeReferenceSearchClue
  ): Observable<GraphNode[]> {
    const { dataType, refType, internalRelationshipId, dataElementId, isInvalidData } =
      nodeReferencesSearchClue;
    const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
      dataType,
      dataElementId
    );
    const dynamicGraphOptions = dynamicDataSetup.getDynamicGraphOptions();

    return this.getDataElementReferences(nodeReferencesSearchClue).pipe(
      take(1),
      switchMap((streamReferencesData) => {
        const allContentFiles = [];
        let streamReferencesMaps = [];

        if (streamReferencesData?.length) {
          streamReferencesMaps = streamReferencesData.map((x) => x.referenceMap);

          const downStreamContentFiles = streamReferencesData
            .map((x) => x.contentFiles)
            .reduce((current, previous) => [...current, ...previous]);
          allContentFiles.push(...downStreamContentFiles);
        }

        const dispatchActions = [];
        if (!(streamReferencesData as any).errors) {
          // add file content data if returned
          if (allContentFiles?.length) {
            const [fileData, thumbnailData] = ContentFileUtils.separateFiles(allContentFiles);
            if (fileData.length) {
              dispatchActions.push(new UpsertContentFiles(fileData));
            }
            if (thumbnailData.length) {
              dispatchActions.push(new UpsertThumbnailFiles(thumbnailData));
            }
          }

          // if upstream/downstream contains parts then we
          // know they have come from an external search
          // ensure they are added to the store
          const storeActions = this.getReferenceDataStoreActions(streamReferencesMaps);
          if (storeActions.length) {
            dispatchActions.push(...storeActions);
          }
        }

        dispatchActions.forEach((a) => this.store$.dispatch(a));

        if (
          !internalRelationshipId &&
          refType === ReferenceType.DOWNSTREAM &&
          dynamicGraphOptions.createInternalDownstreamGraphNodes
        ) {
          return dynamicGraphOptions.createInternalDownstreamGraphNodes(
            this.dataElementCursor,
            streamReferencesMaps,
            isInvalidData
          );
        } else if (
          !internalRelationshipId &&
          refType === ReferenceType.UPSTREAM &&
          dynamicGraphOptions.createInternalUpstreamGraphNodes
        ) {
          const internalGraphNodes$ = dynamicGraphOptions.createInternalUpstreamGraphNodes(
            this.dataElementCursor,
            streamReferencesMaps
          );

          if (dynamicGraphOptions.internalUpstreamReferencesDataTypes) {
            const otherReferencesGraphNodes$ = streamReferencesMaps
              .filter(
                (ref: ReferenceMap) =>
                  !dynamicGraphOptions
                    .internalUpstreamReferencesDataTypes(this.dataElementCursor)
                    .includes(ref.dataElementType)
              )
              .map((ref: ReferenceMap) =>
                this.getDataElementAndCreateGraphNode(
                  ref.dataElement?.id,
                  ref?.referenceData?.dataType ?? ref.dataElementType,
                  ref.referenceData,
                  refType,
                  false
                )
              );

            return combineLatest([
              internalGraphNodes$,
              RxjsUtils.concurrentForkJoin(otherReferencesGraphNodes$),
            ]).pipe(
              take(1),
              map(([internalReferences, otherReferences]) => [
                ...internalReferences,
                ...otherReferences,
              ])
            );
          }

          return internalGraphNodes$;
        } else {
          const referencesGraphNodes = streamReferencesMaps.map((ref: ReferenceMap) =>
            this.getDataElementAndCreateGraphNode(
              ref.dataElement?.id,
              ref?.referenceData?.dataType ?? ref.dataElementType,
              ref.referenceData,
              refType,
              ref.dataElement?.extensionDataType === EnvironmentConstants.FSS_SCHEMA_INVALID_DATA
            )
          );
          return RxjsUtils.concurrentForkJoin(referencesGraphNodes);
        }
      }),
      switchMap((graphData: GraphNode[]) => {
        return this.assignGraphNodeImages(graphData).pipe(
          map(() => {
            return graphData;
          })
        );
      }),
      take(1)
    );
  }

  /**
   * Loads references for the requested data element.
   * Only Parts are loaded externally if required, all other data is taken
   * from the store which has been previously been loaded.
   * @returns {Observable<ReferenceOutput[]>}
   * @memberof DynamicGraphDataService
   */
  getDataElementReferences(
    nodeReferencesSearchClue: NodeReferenceSearchClue
  ): Observable<ReferenceOutput[]> {
    const dataElement = this.dataElementCursor;
    const { dataType, refType, internalRelationshipId, dataElementId } = nodeReferencesSearchClue;
    const isUpstreamReference = refType === ReferenceType.UPSTREAM;
    const setup = this.dynamicDataService.getDynamicDataSetupForType(dataType, dataElementId);
    const graphOptions = setup.getDynamicGraphOptions();
    // ref data types dependent on upstream/downstream
    let referenceDataTypes: ReferenceDataTypeMap[];
    let downStreamPartReferenceDataTypes: ReferenceDataTypeMap[];

    if (isUpstreamReference) {
      referenceDataTypes = graphOptions.upstreamReferenceDataTypes(dataElement).map((x) => ({
        dataType: x,
        reference:
          x === DataElementType.Part
            ? {
                dataType: x,
                externalId: dataElement.externalId,
                referenceType: FabricationReferenceType.Reference,
              }
            : null,
      }));
    } else {
      const filteredDataElements = internalRelationshipId
        ? graphOptions.getInternalDownstreamReferenceFilteredElements(
            dataElement,
            internalRelationshipId
          )
        : dataElement.fabricationReferences;

      referenceDataTypes = filteredDataElements?.length
        ? filteredDataElements
            .filter(
              (x) =>
                !excludedDataTypes.includes(x.dataType) &&
                !graphOptions.ignoreDownstreamReferences?.includes(x.dataType)
            )
            .map((x) => ({ dataType: x.dataType, reference: x }))
        : [];
    }

    if (!referenceDataTypes.length) {
      // placeholder node
      referenceDataTypes = [
        {
          dataType: null,
          reference: {
            dataType: null,
            externalId: '-2',
            referenceType: null,
          },
        },
      ];
    }

    const getDataElementReferences$: Observable<ReferenceOutput[]>[] = [];

    // get any downStream Parts (i.e. parts that need to be called by their externalIds)
    // we need to treat differently and combine into a single call to FCS
    if (!isUpstreamReference) {
      downStreamPartReferenceDataTypes = referenceDataTypes.filter(
        (x) => x.dataType === DataElementType.Part
      );

      if (downStreamPartReferenceDataTypes?.length) {
        referenceDataTypes = referenceDataTypes.filter((x) => x.dataType !== DataElementType.Part);
        getDataElementReferences$.push(this.getPartsByExternalId(downStreamPartReferenceDataTypes));
      }
    }

    referenceDataTypes.forEach((refData) => {
      // parts are the only data type that should need an external search api call
      // all other data should be in the store
      if (refData.dataType !== DataElementType.Part) {
        getDataElementReferences$.push(this.getStoreReferences(refData, nodeReferencesSearchClue));
      } else if (isUpstreamReference) {
        getDataElementReferences$.push(
          this.getExternalReferences(refData, nodeReferencesSearchClue)
        );
      }
    });

    return RxjsUtils.concurrentForkJoin(getDataElementReferences$).pipe(
      take(1),
      map((dataElements: ReferenceOutput[][]) => {
        // flatten
        if (dataElements?.length) {
          let filteredElements = dataElements.reduce((current, previous) => [
            ...current,
            ...previous,
          ]);

          if (internalRelationshipId && refType === ReferenceType.UPSTREAM) {
            filteredElements = graphOptions.getInternalUpstreamReferenceFilteredElements(
              filteredElements,
              internalRelationshipId
            );
          }

          return filteredElements;
        } else {
          return [];
        }
      })
    );
  }

  /**
   * Load parts by their externalIds.
   * @template T
   * @returns {Observable<ReferenceOutput[]>}
   * @memberof DynamicGraphDataService
   */
  getPartsByExternalId<T extends ForgeContentDataElement>(
    refData: ReferenceDataTypeMap[]
  ): Observable<ReferenceOutput[]> {
    const config = this.configCursor;
    let externalIds = refData.map((x) => x.reference.externalId).filter((x) => !!x);
    let existingParts: T[] = [];

    return this.checkPartsFromStoreByExternalId(externalIds).pipe(
      tap(() => this.store$.dispatch(new LoadData())),
      switchMap((parts: T[]) => {
        existingParts = parts;
        // if we have requested parts in the store then filter the externalIds
        // to prevent reload
        if (existingParts?.length) {
          const existingPartExternalIds = existingParts.map((x) => x.externalId);
          externalIds = externalIds.filter((x) => !existingPartExternalIds.includes(x));
        }

        return this.forgeContentService.getPartContentByExternalId<T>(externalIds, config).pipe(
          map((data: [T[], ContentFile[]] | ApiError) => {
            // todo: check for apiError
            // eslint-disable-next-line prefer-const
            let [content, contentFiles] = data as [T[], ContentFile[]];
            content = content.concat(existingParts);
            let referenceOutput: ReferenceOutput[] = [];

            if (content?.length) {
              referenceOutput = refData.map((x) => ({
                referenceMap: {
                  dataElement: content.find((y) => y.externalId === x.reference.externalId),
                  dataElementType: x.dataType, // should always be Part
                  referenceData: x.reference,
                  isInvalidData: false,
                },
                contentFiles: [],
              }));

              referenceOutput[0].contentFiles = contentFiles;

              return referenceOutput;
            } else {
              return [];
            }
          })
        );
      }),
      tap(() => this.store$.dispatch(new LoadDataSuccess()))
    );
  }

  /**
   * Get parts from the store by extrenalId, make sure data that exists is not loaded again
   * @param {string[]} externalIds
   * @returns {Observable<ForgeContentDataElement[]>}
   * @memberof DynamicGraphDataService
   */
  checkPartsFromStoreByExternalId(externalIds: string[]): Observable<ForgeContentDataElement[]> {
    return this.store$.select(selectAllParts).pipe(
      take(1),
      map((allParts: ForgeContentDataElement[]) => {
        const existingParts = allParts.filter((x) => externalIds.includes(x.externalId));
        return existingParts;
      })
    );
  }

  /**
   * Load data from store when the data type is not a Part.
   * @param {ReferenceDataTypeMap} refData
   * @param {ForgeContentDataElement} dataElement
   * @returns {Observable<ReferenceOutput[]>}
   * @memberof DynamicGraphDataService
   */
  getStoreReferences(
    refData: ReferenceDataTypeMap,
    nodeReferencesSearchClue: NodeReferenceSearchClue
  ): Observable<ReferenceOutput[]> {
    const dataElement = this.dataElementCursor;
    const isUpstreamReference: boolean =
      nodeReferencesSearchClue.refType === ReferenceType.UPSTREAM;
    const addPlaceHolder = (): ReferenceOutput => {
      const placeHolderReferenceMap: ReferenceMap = {
        dataElement: null,
        dataElementType: refData.dataType ?? (GC.PLACEHOLDER_GRAPH_NODE_NODATATYPE as any),
        referenceData: refData.reference,
      };
      const placeHolderOutput: ReferenceOutput = {
        referenceMap: placeHolderReferenceMap,
        contentFiles: [],
      };

      return placeHolderOutput;
    };

    if (
      (!isUpstreamReference &&
        (!refData?.reference?.externalId ||
          EnvironmentConstants.FCS_UNASSIGNED_COLLECTION.includes(refData.reference.externalId))) ||
      (isUpstreamReference && refData.dataType === null)
    ) {
      return of([addPlaceHolder()]);
    }

    const referenceSetup = this.dynamicDataService.getDynamicDataSetupForType(
      refData.dataType,
      dataElement.id
    );

    return referenceSetup.options.selectors.selectAll(true).pipe(
      take(1),
      map((allElements: ForgeContentDataElement[]) => {
        let found = [];
        if (allElements) {
          if (isUpstreamReference) {
            const refs = allElements.filter((x) =>
              x.fabricationReferences.find((y) => y.externalId === dataElement.externalId)
            );
            found = found.concat(refs);
          } else {
            const refs = allElements.filter((x) => x.externalId === refData.reference.externalId);
            found = found.concat(refs);
          }
        }

        return found;
      }),
      map((filteredElements: ForgeContentDataElement[]) => {
        const references: ReferenceOutput[] = [];
        if (filteredElements.length) {
          filteredElements.forEach((x) => {
            const referenceMap: ReferenceMap = {
              dataElement: x,
              dataElementType: refData.dataType,
              referenceData: refData.reference,
            };
            const output: ReferenceOutput = {
              referenceMap,
              contentFiles: [],
            };
            references.push(output);
          });
        } else {
          // add place holder if no data found
          references.push(addPlaceHolder());
        }

        return references;
      })
    );
  }

  /**
   * Load parts that match the specified reference.
   * @template T
   * @param {ReferenceDataTypeMap} refData
   * @param {Config} config
   * @returns {Observable<ReferenceOutput[]>}
   * @memberof DynamicGraphDataService
   */
  getExternalReferences(
    refData: ReferenceDataTypeMap,
    nodeReferencesSearchClue: NodeReferenceSearchClue
  ): Observable<ReferenceOutput[]> {
    const config = this.configCursor;
    const { updatedPartExternalId } = nodeReferencesSearchClue;

    let existingParts: Part[] = [];
    // empty slots or not set nodes case
    if (
      !refData.reference.externalId ||
      EnvironmentConstants.FCS_UNASSIGNED_COLLECTION.includes(refData.reference.externalId)
    ) {
      return of([null, null]);
    } else {
      return this.checkPartsFromStoreByReference(refData).pipe(
        tap(() => this.store$.dispatch(new LoadData())),
        switchMap((parts: Part[]): Observable<ReferenceOutput[]> => {
          existingParts = parts;
          let externalIds = [];

          // if we have requested parts in the store then filter the externalIds
          // to prevent reload
          if (existingParts?.length) {
            externalIds = existingParts.map((x) => x.externalId);
          }

          // if we saved an external part id previously then add it in order to exclude that part
          // in api search call and avoid refresh the already updated store with
          // outaded data from FC
          if (updatedPartExternalId) {
            externalIds.push(updatedPartExternalId);
          }

          return this.forgeContentService
            .getPartContentByReference(refData.reference.externalId, config, externalIds)
            .pipe(
              map((data: [Part[], ContentFile[]] | ApiError) => {
                // todo: check for apiError
                // eslint-disable-next-line prefer-const
                let [parts, contentFiles] = data as [Part[], ContentFile[]];
                parts = parts.concat(existingParts).sort((a, b) => a.name.localeCompare(b.name));
                let referenceOutput: ReferenceOutput[] = [];

                if (parts?.length) {
                  referenceOutput = parts.map((x) => ({
                    referenceMap: {
                      dataElement: x,
                      dataElementType: refData.dataType,
                      referenceData: refData.reference,
                    },
                    contentFiles: [],
                  }));

                  referenceOutput[0].contentFiles = contentFiles;

                  return referenceOutput;
                } else {
                  // return default placeholder if there are not references
                  return [
                    {
                      referenceMap: {
                        dataElement: null,
                        dataElementType: GC.PLACEHOLDER_GRAPH_NODE_NODATATYPE as any,
                        referenceData: refData.reference,
                      },
                      contentFiles: [],
                    },
                  ];
                }
              })
            );
        }),
        tap(() => this.store$.dispatch(new LoadDataSuccess()))
      );
    }
  }

  /**
   * * Get parts from the store by reference, make sure data that exists is not loaded again
   * @param {ReferenceDataTypeMap} refdata
   * @returns {Observable<ForgeContentDataElement[]>}
   * @memberof DynamicGraphDataService
   */
  checkPartsFromStoreByReference(
    refdata: ReferenceDataTypeMap
  ): Observable<ForgeContentDataElement[]> {
    return this.store$.select(selectAllParts).pipe(
      take(1),
      map((allParts: ForgeContentDataElement[]) => {
        return allParts.filter((x) =>
          x.fabricationReferences.find((y) => y.externalId === refdata.reference.externalId)
        );
      })
    );
  }

  /**
   * Get from store and serilize component data element to graph node object required
   * for relationship view
   *
   */
  getDataElementAndCreateGraphNode(
    dataElementId: string,
    dataType: DataElementType,
    referenceMetaData: FabricationReference,
    refType = ReferenceType.DOWNSTREAM,
    isInvalidData: boolean
  ): Observable<GraphNode> {
    const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
      dataType,
      dataElementId
    );
    if (dataType && (dataType as string) === GC.PLACEHOLDER_GRAPH_NODE_NODATATYPE) {
      return of(GraphNodeBuilderDirector.makeNoDataGraphNode());
    }

    if (
      (!dataElementId || EnvironmentConstants.FCS_UNASSIGNED_COLLECTION.includes(dataElementId)) &&
      dataType
    ) {
      const { isReplaceable, isRemovable, isEditable } = dynamicDataSetup.getDynamicGraphOptions();
      const nodeOptions: NodeOptions = { isReplaceable, isRemovable, isEditable };
      const targetGraphNode: GraphNode = GraphNodeBuilderDirector.makeEmptySlotNode(
        dataType,
        nodeOptions,
        referenceMetaData
      );

      return of(targetGraphNode);
    }

    return dynamicDataSetup.options.selectors.selectById(dataElementId, isInvalidData).pipe(
      take(1),
      filter((dataElement: ForgeContentDataElement) => {
        return !!dataElement;
      }),
      switchMap((dataElement: ForgeContentDataElement) => {
        const hasFabricationReferences = !!dataElement?.fabricationReferences?.length;

        if (isInvalidData) {
          // change the data type
          dataType = dynamicDataSetup.options.dataType;
        }

        const {
          isReplaceable,
          isRemovable,
          isEditable,
          nodeInfoFields,
          isUpstreamRefBlocked,
          isDownstreamRefBlocked,
        } = dynamicDataSetup.getDynamicGraphOptions();

        const isExpandable =
          (refType === ReferenceType.DOWNSTREAM &&
            (!isDownstreamRefBlocked || !isDownstreamRefBlocked(dataElement))) ||
          (refType === ReferenceType.UPSTREAM && !isUpstreamRefBlocked);

        const nodeOptions: NodeOptions = {
          isReplaceable,
          isRemovable,
          isEditable,
          isExpandable,
          isFocusable: hasFabricationReferences,
        };

        const graphNode: GraphNode = GraphNodeBuilderDirector.makeDataElementGraphNode(
          dataElement,
          dataType,
          nodeInfoFields,
          nodeOptions,
          referenceMetaData,
          isInvalidData || dataElement.isInvalid,
          dynamicDataSetup.requiresBinaryUpgrade(dataElement)
        );

        return of(graphNode);
      }),
      map((node: GraphNode) => node)
    );
  }

  assignGraphNodeImages(nodes: GraphNode[]): Observable<boolean> {
    const getContentFiles$: Observable<[ForgeContentDataElement, GraphNode, ContentFile]>[] = [];
    nodes.forEach((x) => {
      if (x.dataType !== GC.PLACEHOLDER_GRAPH_NODE_NODATATYPE && !x.internalRelationshipId) {
        const dataElementType = x.dataType as DataElementType;

        const setup = this.dynamicDataService.getDynamicDataSetupForType(dataElementType, x.id);

        if (setup) {
          const dynamicGraphOptions = setup.getDynamicGraphOptions();
          const { hasImage } = dynamicGraphOptions;
          if (hasImage) {
            getContentFiles$.push(
              setup.options.selectors
                .selectById(x.dbid, dataElementType === DataElementType.InvalidData)
                .pipe(
                  take(1),
                  switchMap((dataElement: ForgeContentDataElement) => {
                    if (dataElement?.thumbnails?.length) {
                      return this.store$
                        .select(selectThumbnailFileById(dataElement.thumbnails[0]))
                        .pipe(
                          take(1),
                          map(
                            (contentFile: ContentFile) =>
                              [dataElement, x, contentFile] as [
                                ForgeContentDataElement,
                                GraphNode,
                                ContentFile
                              ]
                          ),
                          catchError(() => {
                            return of([dataElement, x, null] as [
                              ForgeContentDataElement,
                              GraphNode,
                              ContentFile
                            ]);
                          })
                        );
                    }

                    return of([dataElement, x, null] as [
                      ForgeContentDataElement,
                      GraphNode,
                      ContentFile
                    ]);
                  })
                )
            );
          }
        }
      }
    });

    if (getContentFiles$?.length) {
      return combineLatest(getContentFiles$).pipe(
        map((contentData: [ForgeContentDataElement, GraphNode, ContentFile][]) =>
          contentData.filter((x) => !!x && !x.some((y) => !y))
        ),
        switchMap((contentData: [ForgeContentDataElement, GraphNode, ContentFile][]) => {
          const objectKeys = contentData.map((x) => x[2].objectKey);
          return this.fileStorageService.getImageFiles(objectKeys).pipe(
            map((storageFile: StorageFile[]) => {
              storageFile.forEach((storageFile) => {
                const contentDataOfImage = contentData.filter(
                  (x) => x[2].objectKey === storageFile.id
                );
                contentDataOfImage.forEach(([, node]) => {
                  node.image = storageFile.contents || null;
                });
              });
            })
          );
        }),
        map(() => true)
      );
    } else {
      return of(true);
    }
  }

  getReferenceDataStoreActions(streamReferenceMaps: ReferenceMap[]): Action[] {
    const storeActions: Action[] = [];
    const streamPartReferences = streamReferenceMaps
      .filter((x) => x.dataElementType === DataElementType.Part)
      .map((x) => x.dataElement)
      .filter((x) => !!x);

    if (streamPartReferences.length) {
      const dynamicSetup = this.dynamicDataService.getDynamicDataSetupForType(DataElementType.Part);
      const action = dynamicSetup.options.actions.loadSuccessAction();
      action.data = streamPartReferences;
      storeActions.push(action);
    }

    return storeActions;
  }

  // ==== Hierarchy and d3 data manipulation methods ====

  getInitialHierarchy(
    dataElementId: string,
    dataType: DataElementType
  ): Observable<DynamicGraphHierarchy> {
    return this.getInitialGraphData(dataElementId, dataType).pipe(
      take(1),
      switchMap(([leftGraphData, rightGraphData]: [GraphData, GraphData]) => {
        this.defineHierarchyNodes(leftGraphData, rightGraphData);
        return this.hierarchy$;
      })
    );
  }

  /**
   * creates a d3 hierarchy layout data from the graph data (tree nodes info)
   * and attach it to the main hierarchy data prop in the component class
   *
   */
  defineHierarchyNodes(leftGraphData: GraphData, rightGraphData: GraphData) {
    const hierarchyData = this.hierarchySubject.getValue();

    [TreePosition.UPSTREAM, TreePosition.DOWNSTREAM].forEach((position) => {
      // Define the childrens descendants tree
      const graphData = position === TreePosition.UPSTREAM ? leftGraphData : rightGraphData;
      const treeData = {
        ...graphData,
        children: [],
      };

      // Define the left and right nodes for expose in the tree
      hierarchyData[position] = hierarchy(treeData);

      hierarchyData[position].descendants().forEach((d) => {
        const clusters = this.getClustersFromNodes(d.children);

        d.id = generateNodeId(d);
        d._children = d.children;
        d.clusters = clusters;
        d.children = null;
      });
    });

    this.hierarchySubject.next(hierarchyData);
  }

  triggerUpdateNodeInfo(updatedDataElementId: string, dataType: DataElementType) {
    const currentHierarchyData = this.hierarchySubject.getValue();
    let updatedNode = null;
    let treePosition = TreePosition.DOWNSTREAM;

    // first we need to define which is the treeposition
    updatedNode = currentHierarchyData[TreePosition.DOWNSTREAM]
      .descendants()
      .find((d) => d.data.dbid === updatedDataElementId);

    if (!updatedNode) {
      treePosition = TreePosition.UPSTREAM;
      updatedNode = currentHierarchyData[TreePosition.UPSTREAM]
        .descendants()
        .find((d) => d.data.dbid === updatedDataElementId);
    }

    const referenceType =
      treePosition === TreePosition.DOWNSTREAM ? ReferenceType.DOWNSTREAM : ReferenceType.UPSTREAM;

    if (referenceType === ReferenceType.DOWNSTREAM) {
      this.triggerUpdateDownstreamNode(updatedDataElementId, dataType);
    } else {
      this.triggerUpdateUpstreamNode(updatedNode, dataType);
    }
  }

  /**
   * update steps when we edit a upstream node and then come back
   */
  private triggerUpdateUpstreamNode(
    currentUpdatedNode: HierarchyGraphNode,
    dataType: DataElementType
  ) {
    if (currentUpdatedNode?.parent?.data.internalRelationshipDataType) {
      currentUpdatedNode = currentUpdatedNode.parent;
    }

    const currentHierarchyData = this.hierarchySubject.getValue();
    const parentUpdatedNode = currentUpdatedNode.parent;

    if (currentUpdatedNode)
      if (parentUpdatedNode) {
        this.configCursor = null;
        this.dataElementCursor = null;
        const nodeReferencesSearchClue: NodeReferenceSearchClue = {
          dataElementId: parentUpdatedNode.data.dbid,
          dataType: parentUpdatedNode.data.dataType as DataElementType,
          refType: ReferenceType.UPSTREAM,
          isInvalidData: parentUpdatedNode.data.isInvalidData,
        };

        // current updated part needs to be excluded from api search call,
        // this saves the part external
        if (dataType === DataElementType.Part) {
          nodeReferencesSearchClue.updatedPartExternalId = currentUpdatedNode.data.id;
        }

        this.getStreamReferences(nodeReferencesSearchClue)
          .pipe(take(1))
          .subscribe((references: GraphNode[]) => {
            const updatedReference = currentUpdatedNode.data.internalRelationshipId
              ? references.find(
                  (r) =>
                    r.internalRelationshipDataType ===
                      currentUpdatedNode.data.internalRelationshipDataType &&
                    r.internalRelationshipId === currentUpdatedNode.data.internalRelationshipId
                )
              : references.find((r) => r.dbid === currentUpdatedNode.data.dbid);

            if (updatedReference) {
              // just update info and preserve children info
              currentUpdatedNode.data.info = updatedReference.info;
              currentUpdatedNode.data.image = updatedReference.image;
              currentUpdatedNode.revalidate = true;

              this.hierarchySubject.next(currentHierarchyData);
            } else {
              //refresh all references

              // placeholder for new nodes data
              const subGraphData = {
                id: '0',
                children: references,
                dataType: '',
              };
              const refHierarchyNodes: HierarchyGraphNode[] =
                hierarchy(subGraphData).children || [];
              refHierarchyNodes.forEach((d: any) => {
                // set as any to remove warnings on read only props

                d.depth = parentUpdatedNode.depth + 1;
                d.height = parentUpdatedNode.height;
                d.parent = parentUpdatedNode;
              });

              parentUpdatedNode.data.children = references;
              parentUpdatedNode.children = refHierarchyNodes;

              parentUpdatedNode.descendants().forEach((d) => {
                if (d !== parentUpdatedNode) {
                  const clusters = this.getClustersFromNodes(d.children);

                  d.id = generateNodeId(d);
                  d._children = d.children;
                  d.clusters = clusters;
                  d.children = null;
                } else {
                  d.id = generateNodeId(d);
                  d._children = d.children;
                }
              });

              parentUpdatedNode.id = generateNodeId(parentUpdatedNode);

              if (parentUpdatedNode.clusters.length === 0) {
                parentUpdatedNode.clusters =
                  parentUpdatedNode.clusters ??
                  this.getClustersFromNodes(parentUpdatedNode._children);
              }

              this.refreshNodeChildrenFromClusters(parentUpdatedNode);
              this.hierarchySubject.next(currentHierarchyData);
            }
          });
      }
  }

  private triggerUpdateDownstreamNode(updatedDataElementId: string, dataType: DataElementType) {
    const currentHierarchyData = this.hierarchySubject.getValue();

    const getRightNode = this.getDataElementAndCreateGraphNode(
      updatedDataElementId,
      dataType,
      null,
      ReferenceType.DOWNSTREAM,
      false
    );

    const graphNode$ = getRightNode.pipe(
      take(1),
      switchMap((graphNode: GraphNode) => {
        return this.assignGraphNodeImages([graphNode]).pipe(
          map(() => {
            return graphNode;
          })
        );
      }),
      take(1)
    );

    return graphNode$.pipe(take(1)).subscribe((graphNode: GraphNode) => {
      const updatedHierarchyNode: HierarchyGraphNode = hierarchy(graphNode);

      const currentStreamHierarchyData = currentHierarchyData[TreePosition.DOWNSTREAM];
      currentStreamHierarchyData
        .descendants()
        .filter((d) => d.data.dbid === updatedDataElementId)
        .forEach((d: HierarchyGraphNode) => {
          const nextClusters = this.getClustersFromNodes(updatedHierarchyNode.children);
          d.data.info = updatedHierarchyNode.data.info;
          d.data.image = updatedHierarchyNode.data.image;
          d.data.children = updatedHierarchyNode.data.children;
          d.id = generateNodeId(d);
          d.revalidate = true;
          d._children = updatedHierarchyNode.children;
          d.clusters = nextClusters;
          d.children = null;
        });

      const currentUpdatedNode: HierarchyGraphNode = currentStreamHierarchyData
        .descendants()
        .find((d) => d.data.dbid === updatedDataElementId);

      const updatedNodeParent = currentUpdatedNode.parent ?? currentUpdatedNode;
      this.applyNodesPaginationChanges(updatedNodeParent);
    });
  }

  /**
   * Generate initial data and pagination of nodes and create groups data for children
   */
  getClustersFromNodes(nodes: HierarchyGraphNode[]): Cluster[] {
    const orderedNodes = orderBy(nodes, ['data.dataType']);
    const dataTypesInNodes = uniq(orderedNodes.map((n) => n.data.dataType));

    const clusters = dataTypesInNodes.map((dataType) => {
      const paginationOptions = {
        ...defaultPaginationOptions,
        dataTypeFilter: dataType as DataElementType,
      };
      const { items, paginationMetaData } = this.paginate(nodes, paginationOptions);
      const parentNode = nodes[0].parent;
      const parentId = parentNode?.id ?? 'root';
      return {
        id: `cluster-${parentId}-${dataType}`,
        dataType,
        nodes: items,
        paginationOptions,
        paginationMetaData,
      };
    });

    return clusters;
  }

  /**
   * It returns an object with the pagination data object and the paginated items,
   * pagination data mainly contains info about the pagination (sort, limit, search, ...)
   * and the paginated items it's are the given children nodes filtered
   * by the pagination criteria
   *
   */
  paginate(
    nodes: any[] = [],
    paginationOptions: PaginationOptions = defaultPaginationOptions
  ): { paginationMetaData: PaginationMetaData; items: any[] } {
    let items = [];
    let nodesAfterSearch = [];
    if (!isEmpty(nodes)) {
      if (paginationOptions.dataTypeFilter) {
        nodes = nodes.filter((node) => node?.data?.dataType === paginationOptions.dataTypeFilter);
      }
      // escape any special chars
      const searchRegExp = escapeRegExp(paginationOptions.search);
      nodesAfterSearch = !isEmpty(paginationOptions.search)
        ? nodes.filter(
            (o) =>
              o?.data?.info?.name?.match(new RegExp(searchRegExp, 'i')) ||
              o?.data?.info?.description?.match(new RegExp(searchRegExp, 'i'))
          )
        : [...nodes];

      items =
        nodesAfterSearch && nodesAfterSearch.length > 0
          ? _take(
              drop(nodesAfterSearch, (paginationOptions.page - 1) * paginationOptions.limit),
              paginationOptions.limit
            )
          : [
              generateFixedNoResultNodeData(
                paginationOptions.search,
                nodes[0].parent,
                nodes[0].data.dataType
              ),
            ];
    }

    items = sortBy(items, ['data.referenceMetaData.index']);

    const paginationMetaData: PaginationMetaData = {
      totalCount: nodes.length,
      totalPages: Math.ceil(nodesAfterSearch.length / paginationOptions.limit),
    };

    return { paginationMetaData, items };
  }

  toggleNode(node: HierarchyGraphNode, treePosition: TreePosition) {
    const currentHierarchyData = this.hierarchySubject.getValue();
    const currentStreamHierarchyData = currentHierarchyData[treePosition];
    const currentToggledNode: HierarchyGraphNode = currentStreamHierarchyData
      .descendants()
      .find((d) => d === node);

    if (!currentToggledNode.children) {
      this.configCursor = null;
      this.dataElementCursor = null;

      const nodeReferencesSearchClue = {
        dataElementId: node.data.dbid,
        dataType: node.data.dataType as DataElementType,
        refType:
          treePosition === TreePosition.DOWNSTREAM
            ? ReferenceType.DOWNSTREAM
            : ReferenceType.UPSTREAM,
        internalRelationshipId: currentToggledNode.data.internalRelationshipId,
        isInvalidData: node.data.isInvalidData,
      };

      // get references and update node
      this.getStreamReferences(nodeReferencesSearchClue)
        .pipe(take(1))
        .subscribe((references: GraphNode[]) => {
          // placeholder to store results in children prop
          const subGraphData = {
            id: '0',
            children: references,
            dataType: '',
          };
          // todo: if no references display an empty node informing user there is no data
          const refHierarchyNodes: HierarchyGraphNode[] = hierarchy(subGraphData).children || [];
          refHierarchyNodes.forEach((d: any) => {
            // set as any to remove warnings on read only props

            d.depth = currentToggledNode.depth + 1;
            d.height = currentToggledNode.height;
            d.parent = currentToggledNode;
          });

          currentToggledNode.data.children = references;
          currentToggledNode.children = refHierarchyNodes;

          currentToggledNode.descendants().forEach((d) => {
            const clusters = this.getClustersFromNodes(d.children);

            d.id = generateNodeId(d);
            d._children = d.children;
            d.clusters = clusters;
            d.children = null;
          });

          this.applyNodesPaginationChanges(currentToggledNode);
        });
    } else {
      // collapse nodes
      currentToggledNode.descendants().forEach((child) => {
        if (child !== currentToggledNode) {
          child.children = null;
        }
      });
      currentToggledNode.children = null;
      this.hierarchySubject.next(currentHierarchyData);
    }
  }

  refreshNodeChildrenFromClusters(currentNode: HierarchyGraphNode) {
    currentNode.clusters.forEach((c: Cluster) => {
      const { items, paginationMetaData } = this.paginate(
        currentNode._children,
        c.paginationOptions
      );
      c.nodes = items;
      c.paginationMetaData = paginationMetaData;
    });

    const totalNodes = flatten(currentNode.clusters.map((c: Cluster) => c.nodes));

    if (!isEmpty(totalNodes)) {
      currentNode.children = totalNodes;
    }
  }

  /**
   * it takes the pagination info changed from a node
   * get the paginated elements (children),
   * it also shows children (using hidden ones in ._children)
   * and triggers a visual update with the new pagination data
   */
  private applyNodesPaginationChanges(currentNode: HierarchyGraphNode, affectedCluster?: Cluster) {
    const currentHierarchyData = this.hierarchySubject.getValue();

    // if change was triggered from within a cluster (pagination, search, sort)
    // then collapse only nodes that belong to the changed cluster,
    // otherwise collapse every node on the same level with their descendants
    if (affectedCluster) {
      this.collapseAllNodesInCluster(affectedCluster);
    } else {
      this.collapseAllSiblings(currentNode);
    }

    this.refreshNodeChildrenFromClusters(currentNode);

    this.hierarchySubject.next(currentHierarchyData);
  }

  private collapseAllNodesInCluster(cluster: Cluster) {
    cluster.nodes
      .map((c) => c.descendants?.() ?? [])
      .flat()
      .forEach((descendant) => {
        descendant.children = null;
      });
  }

  private collapseAllSiblings(currentNode: HierarchyGraphNode) {
    currentNode.parent?.children?.forEach((child) => {
      if (child !== currentNode) {
        child.descendants().forEach((subChild) => {
          subChild.children = null;
        });
      }
    });
  }

  /**
   * Handle pagination buttons (next/prev) action (recommended use with throttle func)
   *
   */
  handlePaginationAction(node: HierarchyGraphNode, treePosition, cluster: Cluster, action) {
    const currentHierarchyData = this.hierarchySubject.getValue();
    const currentStreamHierarchyData = currentHierarchyData[treePosition];
    const currentNode: HierarchyGraphNode = currentStreamHierarchyData
      .descendants()
      .find((n) => n === node);
    const currentCluster = currentNode.clusters.find((c) => c === cluster);

    currentCluster.paginationOptions.page = getNextOrPrev(
      currentCluster.paginationMetaData.totalPages,
      currentCluster.paginationOptions.page,
      action
    );

    const parentNode = currentCluster.nodes[0].parent;
    this.applyNodesPaginationChanges(parentNode, cluster);
  }

  handleNodeSearch(
    node: HierarchyGraphNode,
    treePosition: TreePosition,
    cluster: Cluster,
    searchTerm: string
  ) {
    const currentHierarchyData = this.hierarchySubject.getValue();
    const currentStreamHierarchyData = currentHierarchyData[treePosition];
    const currentNode: HierarchyGraphNode = currentStreamHierarchyData
      .descendants()
      .find((n) => n.id === node.id);
    const currentCluster = currentNode.clusters.find((c) => c.id === cluster.id);

    currentCluster.paginationOptions.search = searchTerm;
    currentCluster.paginationOptions.page = 1;
    const parentNode = currentCluster.nodes[0].parent;
    this.applyNodesPaginationChanges(parentNode, cluster);
  }

  handleToggleSort(node: HierarchyGraphNode, treePosition: TreePosition, cluster: Cluster) {
    const currentHierarchyData = this.hierarchySubject.getValue();
    const currentStreamHierarchyData = currentHierarchyData[treePosition];
    const currentNode: HierarchyGraphNode = currentStreamHierarchyData
      .descendants()
      .find((n) => n === node);
    const currentCluster = currentNode.clusters.find((c) => c === cluster);

    currentCluster.paginationOptions.sort =
      currentCluster.paginationOptions.sort === SortOptions.ASC
        ? SortOptions.DESC
        : SortOptions.ASC;

    const parentNode = currentCluster.nodes[0].parent;

    this.applyNodesPaginationChanges(parentNode, cluster);
  }
}
